<template>
  <div class="app" id="app">

    <AppLoaderComponent v-if="loaderActive" />

    <NavBarComponent />

    <router-view/>

<!--    <ChatComponent :contactApiUrl="contactApiUrl" />-->

    <div class="programming-link" >
      <a class="programming-link__button" href="https://programowanie.ucze.net" target="_blank">
        <span class="programming-link__button__text">
          Chesz Tworzyć Aplikacje jak ta ? <br>
        <b>Naucz się programować !</b>
        </span>
        <span class="programming-link__button__icons">
          <i class="fa-solid fa-code"></i>
          <i class="fa-brands fa-square-js"></i>
          <i class="fa-brands fa-css3-alt"></i>
          <i class="fa-brands fa-sass"></i>
          <i class="fa-brands fa-react"></i>
          <i class="fa-brands fa-vuejs"></i>
          <i class="fa-brands fa-bootstrap"></i>

        </span>
      </a>
    </div>

    <FooterComponent />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

import NavBarComponent from "@/components/NavBarComponent";
import FooterComponent from "@/components/FooterComponent";
import ChatComponent from "@/components/ChatComponent";
import AppLoaderComponent from "@/components/AppLoaderComponent";

export default {
  components: {
    ChatComponent,
    FooterComponent,
    NavBarComponent,
    AppLoaderComponent
  },
  computed: {
    ...mapGetters(['contactApiUrl', 'loaderActive'])

  }
}
</script>

<style lang="scss">
@import "bootstrap/dist/css/bootstrap.min.css";
@import "@/assets/fontawesome-free-6.2.1-web/css/all.css";
//@import "@/assets/fontawesome-free-6.2.1-web/scss/regular.scss";

html{
  font-size: 7px;

  @media screen and (min-width: 330px){
    font-size: 8px;
  }

  @media screen and (min-width: 380px){
    font-size: 10px;
  }

  @media screen and (min-width: 450px){
    font-size: 12px;
  }

  @media screen and (min-width: 1367px){
    font-size: 14px;
  }

}

.app{

  font-family: 'Roboto', sans-serif;

  a {
    text-decoration: none;
  }

  img{
    max-width: 100%;
  }

  .written-font{
    font-family: 'element';
  }

  .container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 1.6rem;

    &--flex{
      display: flex;
    }
  }

  .c-ChatComponent {
    margin-bottom: 2em;
    text-align: center;
  }

  .c-FooterComponent{
    //position: absolute;
    //bottom: 0;
    //left: 0;
    //width: 100%;
  }

  .programming-link {
    // doać na dole ikonki font awesome : react, html, vue ...

    display: flex;
    justify-content: center;
    margin-top: 10em;
    &__button {
      padding: 2em;
      line-height: 2em;
      background-color: rgba(darkblue,0.5);
      color: #fff;
      font-weight: 600;
      width: 100%;
      text-align: center;
      &__text {
        font-size: 2em;
        font-family: nasalization;
        letter-spacing: 0.1em;

        b{
          margin-top: 2em;
          font-size: 1.5em;
        }
      }

      &__icons {
        display: flex;
        justify-content: center;
        column-gap: 2em;
        padding: 2em 0 0;
        i{
          font-size: 2em;
        }
      }
    }
  }

}


</style>
