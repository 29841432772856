import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loader: {
      shortCode: false,
    },
  },
  getters: {
    loaderActive(state){
      let isActive = false
      for(const property in state.loader){
        if(state.loader[property]===true) isActive = true
      }
      return isActive
    },
    frontUrl(){
      return location.href.indexOf('localhost') === -1? 'https://pytania.ucze.net' : 'http://localhost:8081'
    },
    apiUrl(){
      return location.href.indexOf('localhost') === -1? 'https://pytania-api.ucze.net' : 'http://localhost:2000'
    },
    contactApiUrl(){
      return location.href.indexOf('localhost') === -1? 'https://contact-api.ittt.pl' : 'http://localhost:2001'
    },
  },
  mutations: {
    setLoader(state, loaderStatus){
      state.loader = {...state.loader, ...loaderStatus}
    },
  },
  actions: {
    setLoader(context, loaderStatus){
      context.commit('setLoader', loaderStatus)
    }
  },
  modules: {
  }
})
