<template>
  <div class="c-EnterCodeComponent">
      <div class="heading">
        <h2 class="heading__text">
          Masz już kod?
        </h2>
      </div>
      <form class="code-form" @submit.prevent="handleForm">
        <div class="input-group mb-3">
          <input type="text" class="form-control form-control-lg" placeholder="wpisz go tutaj! np LJ094"
                 v-model="code"
          >
          <button class="code-form__button btn btn-outline-secondary" id="button-addon2">OK</button>
        </div>
      </form>

    <div class="errors-list" v-if="errors">
      <div class="errors-list__item" v-for="item in errors">
        <div class="errors-list__item__text">
          {{ item.message }}
        </div>
      </div>
    </div>

    <div class="caption">
      <h3 class="caption__text">
        i przejdź do zadania !
      </h3>
    </div>
  </div>
</template>

<script>
// TODO zrobić walidacje kodu, api powinno pozwolić.
import axios from "axios";

export default {
  name: 'EnterCodeComponent',
  props: {
    frontUrl: {
      type: String,
      required: true
    },
    apiUrl: {
      type: String,
      required: true
    },
    setLoader: {
      type: Function,
      required: true
    }
  },
  data(){
    return {
      code: '',
      errors: ''
    }
  },
  methods: {
    async handleForm() {
      this.setLoader({shortCode: true})
      this.errors = ''

      try {
        const data = await axios.post(`${this.apiUrl}/lists/validation`, {
          shortCode: this.code
        })
        this.setLoader({shortCode: false})
        return window.location = `${this.frontUrl}/#/${this.code}`
      } catch (e) {
        this.errors = {...e.response.data.errors}
        this.setLoader({shortCode: false})
      }
    }
  }
}
</script>

<style lang="scss">

.c-EnterCodeComponent {
  background-color: #cccccc1f;
  padding: 1em;
  text-align: center;

  .heading {

    &__text {
      font-size: 2em;
      font-weight: 600;
    }
  }

  .code-form{

    margin: 0 auto 1em auto;

    @media(min-width: 1000px){
      max-width: 50em;
    }

    &__button {
      background-color: rgba(#1D8F37,0.75);
      color: #fff;
      border-color: rgba(#1D8F37,0.75);
      font-weight: 600;
      padding-left: 2em;
      padding-right: 2em;

      &:hover {
        background-color: #1D8F37;
        border-color: #1D8F37;
      }
    }
  }

  .errors-list {
    &__item {
      margin-bottom: 0.5em;
      &__text {
        color: red;
        font-size: 1.5em;
        font-weight: 600;
      }
    }
  }

  .caption {

    &__text {
      font-size: 2em;
    }

  }

}
</style>